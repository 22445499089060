
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n/index";
import { useRoute } from "vue-router";

export default defineComponent({
  name: "kt-landing",
  setup() {
    const { t, te } = useI18n();
    const route = useRoute();
    const token = route.query.act;

    const startStepUrl = computed(() => {
      if (token) {
        const pathFijo = new String("/formulario-familias-paso-inicial?act=");
        const pathVariable = token;
        return pathFijo.concat(pathVariable.toString());
      } else {
        return "/formulario-familias-paso-inicial";
      }
    });

    const i18n = (text) => {
      if (te(text)) {
        return t(text);
      } else {
        return text;
      }
    };

    return {
      i18n,
      token,
      startStepUrl,
    };
  },
});
